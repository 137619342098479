<template>
  <div>
    <v-data-table
      no-data-text="Malumot kiritilmagan"
      :headers="headers"
      :items="filterByOPerDate"
    >
      <template v-slot:[`item.postavshik_price`]="{ item }">
        {{ numberWithSpaces(item.postavshik_price) || 0 }}
      </template>
      <template v-slot:[`item.postavshik_nds_summa`]="{ item }">
        {{ numberWithSpaces(item.postavshik_nds_summa) || 0 }}
      </template>
      <template v-slot:[`item.postavshik_price_with_nds`]="{ item }">
        {{ numberWithSpaces(item.postavshik_price_with_nds) || 0 }}
      </template>
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td></td>
          <td><b>Jami:</b></td>
          <td>
            <b>{{ sumColumn('postavshik_price_with_nds') }}</b>
          </td>
          <td>
            <v-text-field
              v-model="fromDate"
              type="date"
              label="sanadan"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="toDate"
              type="date"
              label="sanagacha"
            ></v-text-field>
          </td>
          <td colspan="2"></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { numberWithSpaces } from '@/utils'
export default {
  data() {
    return {
      fromDate: '',
      toDate: '',
      totalWithNDS: '',
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Mijoz',
          value: 'zayavka_owner',
          width: '300px'
        },

        {
          text: 'Boshlangich sanasi',
          value: 'start_date'
          // filter: value => {
          //     if (!this.toDate && !this.fromDate) return true
          //     if (!this.fromDate && value <= (this.toDate)) return true
          //     if (!this.toDate && value >= (this.fromDate)) return true
          //     if (value >= (this.fromDate) && value <= (this.toDate)) {
          //         return true
          //     }
          // },
        },
        {
          text: 'Yetqazish sanasi',
          value: 'end_date'
        },
        {
          text: 'Kg',
          value: 'post_kg'
        },
        {
          text: 'm3',
          value: 'post_m3'
        },
        {
          text: 'Postavshik narxi',
          value: 'postavshik_price'
        },
        {
          text: 'Postavshik nds summa',
          value: 'postavshik_nds_summa'
        },
        {
          text: 'Postavshik narxi nds blian',
          value: 'postavshik_price_with_nds'
        },
        {
          text: 'Haydovchi',
          value: 'postavshik_driver'
        },
        {
          text: 'Postavshik mashina info',
          value: 'postavshik_truck_info'
        },
        {
          text: 'status',
          value: 'zayavka_status'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch(
      'providerContractDeliveredZayavkas',
      this.$route.params.id
    )
  },
  computed: {
    // donejobs() {
    //     return this.$store.getters.providerContractDeliveredZayavkas
    // },

    filterByOPerDate() {
      return this.$store.getters.providerContractDeliveredZayavkas.filter(
        (value) => {
          if (this.fromDate && !this.toDate) {
            return value.start_date >= this.fromDate
          } else if (!this.fromDate && this.toDate) {
            return value.start_date <= this.toDate
          } else if (this.fromDate && this.toDate) {
            console.log(2323)
            return (
              value.start_date >= this.fromDate &&
              value.start_date <= this.toDate
            )
          } else {
            return true
          }
        }
      )
    }
  },
  methods: {
    numberWithSpaces,
    sumColumn(key) {
      let total = this.filterByOPerDate.reduce((a, b) => a + (b[key] || 0), 0)
      return total.toLocaleString('es-US')
    }
  }
}
</script>

<style></style>
