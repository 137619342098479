<template>
    <div class="card card-custom">
        <v-card>
            <v-tabs v-model="tab" class="wizard-step">
                <v-tab
                    v-for="(item, index) in items"
                    :key="item.tab"
                    @click="tabIndex(index + 1)"
                >
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <!-- 1 -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <div>
                                <h4 class="mb-10 font-weight-bold text-dark">
                                    Shartnoma malumotlari
                                </h4>
                                <div style="display: flex; flex-wrap: wrap">
                                    <div class="Input">
                                        <label>Mijoz nomi</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.provider_name"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Inn</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.provider_inn"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Shartnoma turi</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.contract_type"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Shartnoma raqami</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.contract_number"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Shartnoma bo'lgan sanasi</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.start_date | formatDate"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Shartnoma tugash sanasi</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.end_date | formatDate"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Summa</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.amount"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Faolligi</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.is_active_status"
                                        />
                                    </div>

                                    <div class="Input">
                                        <label>Kategoriya</label>
                                        <input
                                            type="text"
                                            disabled
                                            class="input-text"
                                            :value="getCurrContractDetails.contract_type_name"
                                        />
                                    </div>
                                </div>

                                <!--
                                <div class="input__wrapper">
                                  <div class="col-4">
                                    <v-text-field
                                      label="Mijoz nomi"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.provider_name"
                                      disabled
                                    ></v-text-field>
                                  </div>
                                  <div class="col-4">
                                    <div v-if="getCurrContractDetails.provider_inn !== null">
                                      <v-text-field
                                        label="INN"
                                        outlined
                                        dense
                                        :value="getCurrContractDetails.provider_inn"
                                        disabled
                                      ></v-text-field>
                                    </div>
                                    <div v-else class="col-4">
                                      <v-text-field
                                        label="OKED"
                                        outlined
                                        dense
                                        disabled
                                        placeholder="INN kiritilmagan"
                                      ></v-text-field>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <v-text-field
                                      label="Shartnoma turi"
                                      outlined
                                      dense
                                      disabled
                                      :value="getCurrContractDetails.contract_type"
                                    ></v-text-field>
                                  </div>
                                  <div class="col-4">
                                    <v-text-field
                                      label="Shartnoma raqami"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.contract_number"
                                      disabled
                                    ></v-text-field>
                                  </div>

                                  <div class="col-4">
                                    <v-text-field
                                      label="Shartnoma bo'lgan sanasi"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.start_date"
                                      disabled
                                    ></v-text-field>
                                  </div>


                                  <div class="col-4">
                                    <v-text-field
                                      label="Shartnoma tugash sanasi"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.end_date"
                                      disabled
                                    ></v-text-field>
                                  </div>
                                  <div class="col-4">
                                    <v-text-field
                                      label="Summa"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.amount"
                                      disabled
                                    ></v-text-field>
                                  </div>
                                  <div class="col-4">
                                    <v-text-field
                                      label="Faolligi"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.is_active_status"
                                      disabled
                                    ></v-text-field>
                                  </div>
                                  <div class="col-4">
                                    <v-text-field
                                      label="Kategoriya"
                                      outlined
                                      dense
                                      :value="getCurrContractDetails.contract_type_name"
                                      disabled
                                    ></v-text-field>
                                  </div>
                                </div> -->
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- 2 -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <zayavka/>
                            <!-- <div v-if="getCurrContractDetails.length !== 0">
                              <div class="d-flex justify-content-between">
                                <h4
                                  class="mb-10 font-weight-bold text-dark"
                                  style="text-transform:uppercase"
                                >
                                  {{ getNameCon.name }}

                                  ilova
                                </h4>
                                <div>
                                  <v-btn
                                    color="primary"
                                    :to="'/contractappenreg/' + this.$route.params.id"
                                    dark
                                  >
                                    Maxsus ilova +
                                  </v-btn>
                                </div>
                              </div>
                              <contractappen v-bind:index="returnParamsId"></contractappen>
                            </div> -->
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- 3 -->
                <!-- <v-tab-item>
                  <v-card>
                    <v-card-text>

                       <h3>Bajarilgan ish</h3>
                      <doneworks /> -->
                <!-- <div v-if="getZaDetaislById.length == 0">
                        <h4 class="mb-10 font-weight-bold text-dark">
                          Buyurtmalar topilmadi
                        </h4>
                      </div>
                      <div v-else>
                        <h4 class="mb-10 font-weight-bold text-dark">
                          Buyurtmalar
                        </h4> -->
                <!-- <template>
                          <v-data-table
                            :headers="zayavkaList"
                            :items="getZaDetaislById"
                            class="elevation-1"
                          >
                          </v-data-table>
                        </template> -->
                <!-- </div> -->
                <!-- </v-card-text>
                  </v-card>
                </v-tab-item> -->
                <!-- 4 -->
                <!-- <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <p>Dop soglashenie</p>
                      <ProviderDopContract
                        :id="getCurrContractDetails.parent_contract"
                      /> -->
                <!-- {{ getDopContractDetails }} -->
                <!-- <div>
                        <h4 class="mb-10 font-weight-bold text-dark">
                          Shartnoma malumotlari
                        </h4>
                        <div class="form-group">
                          <label>Mijoz nomi</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.provider_name"
                          />
                        </div>
                        <div class="form-group">
                          <label>INN</label>
                          <div v-if="getDopContractDetails.provider_inn !== null">
                            <input
                              type="text"
                              disabled
                              :value="getDopContractDetails.provider_inn"
                              class="form-control form-control-solid form-control-lg"
                            />
                          </div>
                          <div v-else>
                            <input
                              type="text"
                              disabled
                              placeholder="INN kiritilmagan"
                              class="form-control form-control-solid form-control-lg"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Shartnoma turi</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.contract_type"
                          />
                        </div>
                        <div class="form-group">
                          <label>Shartnoma raqami</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.contract_number"
                          />
                        </div>

                        <div class="form-group">
                          <label>Shartnoma bo'lgan sanasi</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.start_date"
                          />
                        </div>

                        <div class="form-group">
                          <label>Shartnoma tugash sanasi</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.end_date"
                          />
                        </div>
                        <div class="form-group">
                          <label>Summa</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.amount"
                          />
                        </div>
                        <div class="form-group">
                          <label>Faolligi</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.is_active_status"
                          />
                        </div>
                        <div class="form-group">
                          <label>Kategoriya</label>
                          <input
                            type="text"
                            disabled
                            class="form-control form-control-solid form-control-lg"
                            :value="getDopContractDetails.contract_type_name"
                          />
                        </div>
                      </div> -->
                <!-- </v-card-text>
                  </v-card>
                </v-tab-item> -->

                <!-- 6 -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <h3>Hisob Faktura</h3>
                            <invoice/>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- 7 -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <!--
                            <v-row>
                                <v-col cols="2">
                                    <div>
                                        <v-menu
                                            ref="start_date_menu"
                                            v-model="start_date_menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="startDateFormatted"
                                                    label="Sanadan"
                                                    persistent-hint
                                                    clearable
                                                    prepend-icon="mdi-calendar"
                                                    v-bind="start_date_operations = parseDate(startDateFormatted)"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="start_date_operations"
                                                no-title
                                                @input="start_date_menu = false"
                                            ></v-date-picker>
                                        </v-menu>

                                    </div>
                                </v-col>
                            </v-row>
                            -->

                            <payments/>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- 5 -->
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <doneworks/>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
// import ProviderDopContract from "./ProviderDopContract.vue";
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module'
// import KTUtil from "@/assets/js/components/util";
// import KTWizard from "@/assets/js/components/wizard";
import doneworks from './providersContracts/doneworks'
import invoice from './providersContracts/invoice'
import payments from './providersContracts/payments'
import zayavka from './providersContracts/zayavka'
// import order from "./providersContracts/order";
export default {
    components: {doneworks, invoice, payments, zayavka},
    data() {
        return {
            name: '',
            tab: null,
            start_date_menu: false,
            start_date_operations: null,
            startDateFormatted: null,
            items: [
                {
                    tab: 'Shartnoma malumotlari'
                },
                // {
                //   tab: "Shartnoma ilovasi"
                // },
                {tab: 'Buyurtma'},
                // { tab: "Dop soglashenie" },
                {tab: 'Hisob faktura'},
                {tab: 'Tulovlar'},
                {tab: 'Bajarilgan ish'}
            ],
            msg: 'Shartnomalar malumotari',
            selectedID: '',
            fields: [
                {
                    label: 'Mijoz nomi',
                    key: 'zayavka_owner'
                },
                {
                    label: 'INN',
                    key: 'client_inn'
                },
                {
                    label: 'Turi',
                    key: 'contract_type'
                },
                {
                    label: 'Shartnoma raqami',
                    key: 'contract_number'
                },
                {
                    label: 'Yaratilgan sana',
                    key: 'start_date'
                },
                {
                    label: 'Tugash sanasi',
                    key: 'end_date'
                }
            ]
        }
    },
    beforeCreate() {
        this.$store.dispatch('getProviderContractdetail', this.$route.params.id)
        this.$store.dispatch('getAllProvidersContract')
    },
    watch: {
        start_date_operations(val) {
            this.startDateFormatted = this.formatDate(val)
        },
    },
    computed: {
        getCurrContractDetails() {
            const data = this.$store.state.requests.providerContractDetail
            if (data.amount !== undefined && data.amount !== null) {
                data.amount = data.amount.toLocaleString('es-US')
            }
            if (data.contract_type === 'MU') {
                data.contract_type = 'Muddatli'
            } else if (data.contract_type === 'SU') {
                data.contract_type = 'Summa'
            } else {
                data.contract_type = 'Muddatsiz'
            }
            if (data.is_active === true) {
                data.is_active = 'Faol'
            } else {
                data.is_active = 'Nofaol'
            }

            return data
        },
        headers() {
            return [
                {text: 'Id', value: 'id'},
                {text: 'Mijoz nomi', value: 'client_name'},
                {text: 'Inn', value: 'client_inn'},
                {text: 'Turi', value: 'contract_type'},
                {text: 'Reg raqami', value: 'contract_number'},
                {text: 'Boshlanish', value: 'start_date'},
                {text: 'Tugash', value: 'end_date'},
                {text: 'Summa', value: 'amount'},
                {text: 'Faol', value: 'is_active'}
            ]
        },
        zayavkaList() {
            return [
                {text: 'Id', value: 'id'},
                {text: 'Buyurtma ownrer', value: 'zayavka_owner'},
                {text: 'Postavshik', value: 'deliver'},
                {text: 'Narxi', value: 'price'},
                {text: 'Kg', value: 'post_kg'},
                {text: 'm3', value: 'post_m3'},
                {text: 'Toʻlov turi', value: 'payment_type'},
                {text: 'Status', value: 'zayavka_status'}
            ]
        }
    },
    methods: {
        formatDate(date) {

            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [day, month, year] = date.split('-')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        selectedTruck(payload) {
            this.selectedID = payload
        },
        tabIndex(value) {
            if (value === 2) {
                this.$store.dispatch('providerContractZayavka', this.$route.params.id)
            }
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: 'Postavshiklar'},
            {title: 'Postavshik kontrakt malumotlari'}
        ])
        this.$store.dispatch('updateProviderContractOperationFilterStartDate',this.start_date_operations)
    }
}
</script>
<style scoped>
.Input label {
    display: block;
    font-size: 13px;
}

.Input {
    margin-right: 6px;
    margin-bottom: 30px;
}

.Input:last-child {
    margin-right: 0;
}

.input-text {
    background-color: rgb(142 190 253 / 14%);
    padding: 1rem;
    border: 1px solid rgb(142 190 253 / 28%);
    color: inherit;
    /* color: rgb(24, 24, 24); */
    /* box-shadow: 0px 5px 7px 3px rgba(222, 222, 222, 0.66); */

    font-size: 16px;
    border-radius: 3px;
}

.col-4,
.col-12 {
    padding-left: 0;
}

.input__wrapper {
    display: flex;
    flex-wrap: wrap;
}

.v-application .elevation-1 {
    box-shadow: 0 0 30px 0 rgba(177, 177, 177, 0.19) !important;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 0.25rem;
    text-align: left;
    border: 1px solid #ccc;
}

tbody tr:nth-child(odd) {
    background: #eee;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>
<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
    color: inherit;
}
</style>
