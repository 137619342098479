<template>
  <div>
    <v-card>
      <!--
             <v-card-title>
                           <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                               label="Search"
                                single-line
                               hide-details
                           ></v-text-field>
                        </v-card-title>
              -->
      <v-data-table
        :headers="headers"
        :items="filterByOPerDate"
        :items-per-page="5"
      >
        <template v-slot:[`item.amount`]="{ item }">
          {{ numberWithSpaces(item.amount) || 0 }}
        </template>
        <template v-slot:body.append>
          <tr>
            <td></td>
            <td></td>
            <td><b>Jami:</b></td>
            <td>
              <b>{{ sumColumn('amount') }}</b>
            </td>
            <td>
              <v-text-field
                v-model="fromDate"
                type="date"
                label="sanadan"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="toDate"
                type="date"
                label="sanagacha"
              ></v-text-field>
            </td>
            <td colspan="2"></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { numberWithSpaces } from '@/utils'
export default {
  data() {
    return {
      fromDate: '',
      toDate: '',
      totalWithNDS: '',
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Manbasi',
          value: 'transfer_payment_source'
        },
        {
          text: 'Turi',
          value: 'transfer_order_type'
        },
        {
          text: 'Summa',
          value: 'amount'
        },
        {
          text: 'Sana',
          value: 'oper_date'
          // filter: value => {
          //     if (!this.toDate && !this.fromDate) return true
          //     if (!this.fromDate && value <= (this.toDate)) return true
          //     if (!this.toDate && value >= (this.fromDate)) return true
          //     if (value >= (this.fromDate) && value <= (this.toDate)) {
          //         return true
          //     }
          // },
        },
        {
          text: 'Kontrakt#',
          value: 'contract_reg_number'
        },
        {
          text: 'Kontrakt ID',
          value: 'contract'
        },
        {
          text: 'Transfer',
          value: 'transfer'
        },
        {
          text: 'Izoh',
          value: 'comment'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('getProviderContractPayment', this.$route.params.id)
  },
  computed: {
    filterByOPerDate() {
      return this.$store.getters.ProviderContractPayment.filter((value) => {
        if (this.fromDate && !this.toDate) {
          return value.oper_date >= this.fromDate
        } else if (!this.fromDate && this.toDate) {
          return value.oper_date <= this.toDate
        } else if (this.fromDate && this.toDate) {
          return (
            value.oper_date >= this.fromDate && value.oper_date <= this.toDate
          )
        } else {
          return true
        }
      })
    }
  },
  methods: {
    numberWithSpaces,
    sumColumn(key) {
      let total = this.filterByOPerDate.reduce((a, b) => a + (b[key] || 0), 0)
      return total.toLocaleString('es-US')
    }
    // operDateFilter: {
    //     type: Function,
    //     default: (items, search, filter) => {
    //         search = search.toString().toLowerCase()
    //         return items.filter(i => (
    //             Object.keys(i).some(j => filter(i[j], search))
    //         ))
    //     }
    // },
  }
}
</script>

<style></style>
