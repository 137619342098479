<template>
  <div>
    <p>Hisob faktura</p>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
